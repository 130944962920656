body {
  background: #1D1F21 !important;
}

.ff-monospace {
  font-family: monospace;
}

.monaco-editor, .monaco-editor-background, .monaco-editor .inputarea.ime-input {
  background-color: #1d1f2100 !important;
  backdrop-filter: blur(5px) !important;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1) !important;
}

.monaco-editor .margin {
  background-color: #1d1f2100 !important;
  backdrop-filter: blur(5px) !important;
}